import React from "react";
import "../styles.css";

function LeftHeader() {
  return (
    <div className="logo-text-wrapper-div">
      <div>
        <img src="/tmclogo.jpg" alt="logo" />
      </div>
      <div className="logo-text">Partner of Sustainable Growth</div>
    </div>
  );
}

export default LeftHeader;
