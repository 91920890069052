import React, { useState } from "react";
import { FaHome, FaShoppingBag } from "react-icons/fa";
import { GrTask } from "react-icons/gr";
import { FaClipboardCheck } from "react-icons/fa6";
import { AiFillProduct } from "react-icons/ai";
import { LuMonitorCheck } from "react-icons/lu";
import "./styles.css";
import { Link } from "react-router-dom";

export default function TopNavBar() {
  const navList = [
    { label: "HOME", icon: <FaHome style={{ marginRight: "5px" }} /> },
    {
      label: "Company",
      icon: <LuMonitorCheck style={{ marginRight: "5px" }} />,
    },
    { label: "SERVICES", icon: <GrTask style={{ marginRight: "5px" }} /> },
    {
      label: "PRODUCTS",
      icon: <AiFillProduct style={{ marginRight: "5px" }} />,
    },
    {
      label: "CONSULTANCY",
      icon: <FaClipboardCheck style={{ marginRight: "5px" }} />,
    },
  ];
  const [currentNavigator, setCurrentNavigator] = useState("HOME");
  return (
    <div className="wrapperTopNav">
      <nav className="navbarWrapper">
        {navList.map((item, index) => {
          return (
            <div
              onClick={() => setCurrentNavigator(item.label)}
              key={`${item.label + index}`}
              className={`singleNavBarElement ${
                item.label.toLowerCase() === currentNavigator.toLowerCase() &&
                "active-tab"
              }`}
            >
              {" "}
              <Link
                className="singleNavBarElementIconAndLabel"
                to={`/${item.label.toLowerCase()}`}
              >
                {item.icon}
                {item.label}
              </Link>
            </div>
          );
        })}
      </nav>
    </div>
  );
}
