import React from "react";
import LeftPartComponent from "../homeBody/LeftPartComponent";

function ConsultancyBodyThird() {
  return (
    <div>
      <div className="normal-flex-with-row">
        <LeftPartComponent
          firstHeading="TRAINING COURSES"
          firstSubHeading="Certified online and classroom training"
        />
        <div className="image-wrapper added-text">
          <p>
            <span style={{ fontWeight: "bold" }}>
              CERTIFIED TRAINING BY ONLINE AND CLASSROOM COURSES
              <br></br>
            </span>
            We deliver certified training courses for ISO system awareness, lead
            auditor and internal auditors through our classroom training program
            as well as online courses available at our website:
            www.tmc-consulancy.in. TMC Academy is our ISO/IEC 17024 certified
            training platform to offer such ISO training courses online as well
            as classroom training
          </p>
          <ul>
            <li>
              <b>E-Learning Training Courses:</b> Users can purchase such
              courses online, which include handouts, powerpoint presentations,
              video training materials and online test for each session. After
              completing this course successfully, the user will receive a
              certificate for the course.
            </li>
            <li>
              <b>Classroom Training:</b> Our experienced tutors/instructors
              deliver training at either our place or at client's place as per
              pre-decided schedule. After successfully completing such training,
              the trainee will get a training certificate.
            </li>
            <li>
              <b>Online Training Program:</b> We deliver training via digital
              platforms like webinars, video conference and emails.
            </li>
            <li>
              <b>Auditor Training Presentation Kits:</b> We offer readymade
              powerpoint presentation kit. Trainees can purchase it and learn
              themselves or deliver training to others.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ConsultancyBodyThird;
