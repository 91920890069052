import React from "react";

function LeftFooter() {
  return (
    <div>
      <p className="footer-left-text">
        <span className="footer-left-text-bold">Contact Us:</span>
        <br></br>
        <span>
          Plot No. 15, SF-02, Stabadipuram,<br></br> Govindpuram, Ghaziabad
          -201013
        </span>
        <br></br>
        <span>
          Ph: +91 70118 - 32819<br></br> Email: infotaanvi@gmail.com
        </span>
      </p>
    </div>
  );
}

export default LeftFooter;
