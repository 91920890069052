import React from "react";
import "../styles.css";

function RightHeader() {
  return (
    <div className="logo-text-wrapper-div">
      <div className="logo-text-wrapper-div">
        <img src="/SocialMediaLogo.png" alt="logo" />
        <img src="/PhoneUsLogo.png" alt="logo" />
      </div>
    </div>
  );
}

export default RightHeader;
