import React from "react";
import LeftPartComponent from "../homeBody/LeftPartComponent";

function ConsultancyBodyFourth() {
  return (
    <div>
      <div className="normal-flex-with-row">
        <LeftPartComponent
          firstHeading="DOCUMENTATION CONSULANCY"
          firstSubHeading="Total documentation solution as per your requirements"
        />
        <div className="image-wrapper added-text">
          <p>
            <span style={{ fontWeight: "bold" }}>
              4 STEP E-DOCUMENTATION CONSULTANCY PROGRAM
              <br></br>
            </span>
            We offer 4-Step online documentation consultancy for all ISO
            certification and other international systems and product
            certifications. In our e-documentation consultancy program, we
            provide online ISO documents for a quick certification to our
            clients worldwide. We are a team of expert consultants having rich
            experience of implementing the system and/or documentation of ISO
            certification for more than 2000 clients.
          </p>
          <ul>
            <li>
              <b>Gap Assessment Analysis:</b> We conduct a gap analysis on the
              basis of review and analysis of answers to our micro level survey
              questionnaire.
            </li>
            <li>
              <b>Documentation Preparation:</b> Based on the gap assessment and
              review of our micro-level questions, we will prepare your
              documents and provide their softcopy to you.
            </li>
            <li>
              <b>Documentation Requirements in System Implementation:</b> Our
              specialist ISO consultant will be in touch with you on phones and
              emails to discuss the additional documentation requirements during
              the implementation of your management system.
            </li>
            <li>
              <b>Document Review:</b> Once all the process documents are
              finalized by you, we will conduct a desk review of documents and
              suggest necessary changes.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ConsultancyBodyFourth;
