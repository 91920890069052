import React from "react";
import "./styles.css";
import LeftHeader from "./left-header/LeftHeader";
import RightHeader from "./right-header/RightHeader";
import TopNavBar from "../top-navbar/TopNavBar";

export default function Header() {
  return (
    <div className="header-wrapper">
      <LeftHeader />
      <TopNavBar />
      <RightHeader />
    </div>
  );
}
