import React from "react";
import LeftPartComponent from "../homeBody/LeftPartComponent";
import "../styles.css";

function CompanySecondBody() {
  return (
    <div>
      <div className="normal-flex-with-row">
        <LeftPartComponent
          firstHeading="Company"
          secondHeading="Mission & Objectives"
          secondSubText=""
        />
        <div className="image-wrapper added-text">
          <p>
            <p>
              <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
                Mission
              </span>
            </p>
            <span>
              TMC’s mission is to help organizations achieve business excellence
              through a rational development and improvement of the business
              system. With in-depth experience of our staff and our innovative
              approach to system training, business process improvement and
              management system implementation, we are committed to providing
              the highest quality, custom-tailored and affordable training as
              well as consulting services to help the organization achieve
              excellence.
            </span>
          </p>
          <p>
            <p>
              <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
                Objectives
              </span>
            </p>
            <span>
              <ul>
                <li>
                  Provide services to organizations to help achieve compliance
                  as per international standards and regulations
                </li>
                <li>
                  Create TMC worldwide to share knowledge on system documents
                </li>
                <li>
                  Promote management and system training by providing
                  ready-to-use training kits
                </li>
                <li>
                  Provide documents and presentation kits to save the time of
                  individuals in the preparation of own documents/ training
                  materials
                </li>
                <li>Provide value for money to our customers.</li>
              </ul>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default CompanySecondBody;
