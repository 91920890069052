import React from "react";
import LeftPartComponent from "../homeBody/LeftPartComponent";

function ConsultancyBodySecond() {
  return (
    <div>
      <div className="normal-flex-with-row">
        <LeftPartComponent
          firstHeading="CERTIFICATION CONSULTANCY"
          firstSubHeading="Step we follow for system implementation consultancy"
        />
        <div className="image-wrapper added-text">
          <p>
            <span style={{ fontWeight: "bold" }}>
              STEPS FOR SYSTEM IMPLEMENTATION & CERTIFICATION CONSULTANCY
            </span>
            <ul>
              <li>Step 1: Gap Assessment</li> <li>Step 2: Training</li>
              <li>Step 3: Document Generation</li>
              <li>Step 4: Implementation</li> <li>Step 5: Document Review</li>
              <li>Step 6: Internal Auditor training</li>
              <li>
                Step 7: Internal audits, Improvement, and closure of audit
                findings
              </li>{" "}
              <li>Step 8: Management Review Meeting</li>
              <li>Step 9: Registration Audit</li>
              <li>Step10: Close registration audit findings</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ConsultancyBodySecond;
