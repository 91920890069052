import React from "react";
import LeftPartComponent from "../homeBody/LeftPartComponent";
import "../styles.css";

function CompanyThirdBody() {
  return (
    <div>
      <div className="normal-flex-with-row">
        <LeftPartComponent
          firstHeading="Company"
          secondHeading="Careers"
          secondSubText=""
        />
        <div className="image-wrapper added-text">
          <div>
            <p style={{ textAlign: "center", fontSize: "2rem", color: "grey" }}>
              CURRENT <span style={{ color: "#2293d7" }}>OPENINGS</span>
            </p>
          </div>
          <div className="opening-wrapper">
            <div className="opening-single-element">
              <div className="opening-div">ISO CONSULTANT</div>
              <p>
                Fresh or 1 years graduate engineer degree/diploma in
                mechanical,electrical,electronic and computer science.
              </p>
            </div>
            <div className="opening-single-element">
              <div className="opening-div">Accountant</div>
              <p>
                Candidate must have min. 2 years of experience in business
                accounting.
              </p>
            </div>
            <div className="opening-single-element">
              <div className="opening-div">COMPUTER OPERATOR</div>
              <p>
                Computer Operator with 2+ years of Exp. can apply for this job.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyThirdBody;
