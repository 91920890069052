import React from "react";
import ProductBodyFirst from "../../pageBody/productBody/ProductBodyFirst";
import Footer from "../../footer";
import ProductBodySecond from "../../pageBody/productBody/ProductBodySecond";
import ProductBodyThird from "../../pageBody/productBody/ProductBodyThird";

function ProductPage() {
  return (
    <div>
      <ProductBodyThird />
      <ProductBodyFirst />
      <ProductBodySecond />
      <Footer />
    </div>
  );
}

export default ProductPage;
