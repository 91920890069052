import React from "react";

function RightFooter() {
  return (
    <div>
      <img src="/Broucher.png" alt="logo" height="230" />
    </div>
  );
}

export default RightFooter;
