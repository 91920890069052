import React from "react";
import "../styles.css";
import LeftPartComponent from "./LeftPartComponent";

function FifthBody() {
  return (
    <div>
      <div className="normal-flex-with-row">
        <LeftPartComponent
          firstHeading="HOME"
          secondHeading="PRODUCTS"
          secondSubText="reliable certification document kit"
        />
        <div className="image-wrapper added-text">
          <p>
            We provide complete set of documents, training slides with handouts
            for participants; which ensures true value for money to our
            customers. Our documents/training kits are in the form of word files
            and/or ppt presentation slides and it can be edited to make your own
            documents easily and quickly to save your time and resources. Our
            products are made by a team of experienced consultants. We have
            documentation kits and training presentation kits on various ISO
            system certification, ISO accreditation, product certification and
            management areas as below:
            <ul>
              <li>Quality Management System Certification</li>{" "}
              <li>Health, Safety and Environment Certification</li>
              <li>Food Safety Management System Certification</li>
              <li>IT, Software and Services Certification</li>{" "}
              <li>Energy System / Social Certification</li>
              <li>System Accreditation</li>
              <li>Product Certification</li>{" "}
              <li>Good Manufacturing Practices (GMP)</li>
              <li>Management Areas</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
}

export default FifthBody;
