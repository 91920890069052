import React from "react";
import ConsultancyBodyFirst from "../../pageBody/consultancyBody/ConsultancyBodyFirst";
import Footer from "../../footer";
import ConsultancyBodySecond from "../../pageBody/consultancyBody/ConsultancyBodySecond";
import ConsultancyBodyThird from "../../pageBody/consultancyBody/ConsultancyBodyThird";
import ConsultancyBodyFourth from "../../pageBody/consultancyBody/ConsultancyBodyFourth";

function ConsultancyPage() {
  return (
    <div>
      <ConsultancyBodyFirst />
      <ConsultancyBodySecond />
      <ConsultancyBodyThird />
      <ConsultancyBodyFourth />
      <Footer />
    </div>
  );
}

export default ConsultancyPage;
