import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage, CompanyPage, ConsultancyPage } from "./pages";
import Header from "./header/Header";
import ServicePage from "./pages/services-page/ServicePage";
import ProductPage from "./pages/product-page/ProductPage";

export default function AppComponent() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route index element={<HomePage />} />
          <Route path="home" element={<HomePage />} />
          <Route path="services" element={<ServicePage />} />
          <Route path="consultancy" element={<ConsultancyPage />} />
          <Route path="company" element={<CompanyPage />} />
          <Route path="e-shop" element={<HomePage />} />
          <Route path="products" element={<ProductPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}
