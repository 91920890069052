import React from "react";
import LeftPartComponent from "../homeBody/LeftPartComponent";

function ServiceFirstBody() {
  return (
    <div style={{ marginTop: "10rem" }}>
      <div className="normal-flex-with-row">
        <LeftPartComponent
          firstHeading="HOME"
          secondHeading="SERVICES"
          secondSubText="quick and reliable solution"
        />
        <div className="image-wrapper added-text">
          <p>
            TMC is one of the largest business management consulting and
            training company, offering services to organizations of all nature
            and sizes.
            <br></br>{" "}
            <span className="bold-text-with-vertical-padding">
              OUR SERVICES
            </span>
            <br></br>1.ISO System and Product Certification Consultancy (QMS,
            EMS, OHSAS, FSMS, FSSC, BRCGS, HACCP, ISMS, Lab Accreditation, EnMS,
            ITSMS, IMS, SA 8000, Hospital Accreditation, CE Mark and more)
            <br></br>2.Management Consultancy
            <br></br>3.Documentation Consultancy
            <br></br>4.Training on all ISO system and Management areas.
            <br></br>5.Sale of ISO Documents and Training Presentation Kits
            <br></br>6.Certified Training Courses for ISO Auditors
            <br></br>7.Instrument Calibration at our NABL accredited calibration
            laboratory
          </p>
        </div>
      </div>
    </div>
  );
}

export default ServiceFirstBody;
