import React from "react";
import Footer from "../../footer";
import CompanyFirstBody from "../../pageBody/companyBody/Company_firstBody";
import CompanySecondBody from "../../pageBody/companyBody/Company_secondBody";
import CompanyThirdBody from "../../pageBody/companyBody/Company_thirdBody";
import CompanyFourthBody from "../../pageBody/companyBody/Company_fourthBody";

function CompanyPage() {
  return (
    <div className="App">
      <CompanyFirstBody />
      <CompanySecondBody />
      <CompanyFourthBody />
      <CompanyThirdBody />
      <Footer />
    </div>
  );
}

export default CompanyPage;
