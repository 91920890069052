import React from "react";
import LeftPartComponent from "../homeBody/LeftPartComponent";

function ProductBodySecond() {
  return (
    <div>
      <div className="normal-flex-with-row">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <LeftPartComponent
            firstHeading="PRODUCTS"
            firstSubHeading="Low cost, time saving and reliable templates"
          />
        </div>
        <div className="image-wrapper added-text">
          <p>
            <span
              style={{ fontStyle: "italic", fontSize: "1.5rem" }}
              className="bold-text-with-vertical-padding"
            >
              TIME-SAVING, RELIABLE & LOW-COST PRODUCTS:
            </span>
            <br></br>
            <span>
              All our products are developed by a team of highly qualified
              professional consultants. The prices of all the products
              (documentation, presentations, etc.) are very low in comparison to
              the cost of preparing those products.
            </span>{" "}
            <ul>
              <li>Our products provide value for money to our customers.</li>
              <li>
                The products are prepared by a team having global experience of
                more than 700 system certifications and training.
              </li>
              <li>
                100 % return to customers on what they spent on our products.
              </li>
              <li>
                We deliver our products within 12 to 24 hours via FTP
                download/E-mails.
              </li>
              <li>Our billing rates are 40 % lower than the industry rate.</li>
              <li>
                Products' preparation costs involving 9000 man-days are
                distributed across the products.
              </li>
              <li>
                Using our products saves your preparation time and thus cost.
              </li>
              <li>
                All our products are ready-to-use and they can be edited also.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProductBodySecond;
