import React from "react";
import "../styles.css";

function SecondBody() {
  return (
    <div>
      <div className="normal-flex-with-row">
        <div className="second-body-text">
          {" "}
          <div className="text-wrapper">
            <p className="background-color blue">HOME</p>
          </div>
          <div className="text-wrapper">
            <p className="background-color orange">
              SERVICES
              <br></br>
              <span className="subtext">quick and reliable solution</span>
            </p>
          </div>
          <div className="text-wrapper">
            <p className="background-color orange">
              CONSULTANCY<br></br>
              <span className="subtext">iso certification consultancy</span>
            </p>
          </div>
          <div className="text-wrapper">
            <p className="background-color orange">
              PRODUCTS<br></br>
              <span className="subtext">
                reliable certification document kit
              </span>
            </p>
          </div>
          <div className="text-wrapper">
            <p className="background-color orange">E-CONSULTANCY</p>
          </div>
          <div className="text-wrapper">
            <p className="background-color orange">
              E-SHOP<br></br>
              <span className="subtext">ready to us certification KIT</span>
            </p>
          </div>
        </div>
        <div className="image-wrapper">
          <img src="/DartImage.png" alt="logo" />
        </div>
      </div>
    </div>
  );
}

export default SecondBody;
