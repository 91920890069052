import React from "react";
import LeftPartComponent from "../homeBody/LeftPartComponent";
import "../styles.css";

function CompanyFirstBody() {
  return (
    <div style={{ marginTop: "10rem" }}>
      <div className="normal-flex-with-row">
        <LeftPartComponent
          firstHeading="Company"
          secondHeading="About Company"
          secondSubText=""
        />
        <div className="image-wrapper added-text">
          <p>
            <p style={{ textAlign: "center" }}>
              <span
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >
                TAANVI MANAGEMENT CONSALTANT (TMC)
              </span>
              <br></br>
              <span style={{ fontStyle: "italic" }}>
                Global Management Consultancy and Training Company
              </span>
            </p>
            <br></br>
            <span>
              TMC, a progressive company promoted by a group of qualified food
              technologist, engineers and management graduates having a rich
              experience of more than 25 years in certification consultancy and
              management areas. The company serves domestic as well as
              international customers through on-site and off-site modes of
              service delivery. We offer a full range of consulting services
              geared toward helping all types of organizations to achieve
              competitiveness, certifications, and compliance with international
              standards and regulations
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default CompanyFirstBody;
