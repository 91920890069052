import React from "react";
import LeftPartComponent from "../homeBody/LeftPartComponent";

function ProductBodyFirst() {
  return (
    <div style={{ marginTop: "5" }}>
      <div className="normal-flex-with-row">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <LeftPartComponent
            firstHeading="PRODUCTS"
            firstSubHeading="Low cost, time saving and reliable templates"
          />
          <LeftPartComponent
            firstHeading="CERTIFICATION CONSULTANCY"
            firstSubHeading="Step we follow for system implementation consultancy"
          />
          <LeftPartComponent
            firstHeading="TRAINING COURSES"
            firstSubHeading="Certified online and classroom training"
          />
          <LeftPartComponent
            firstHeading="DOCUMENTATION CONSULTANCY"
            firstSubHeading="Total documentation solution as per your requirements"
          />
        </div>
        <div className="image-wrapper added-text">
          <img
            style={{ margin: "0.5rem" }}
            height={500}
            width={500}
            src="/System.jpg"
            alt="logo"
          />
        </div>
      </div>
    </div>
  );
}

export default ProductBodyFirst;
