import React from "react";
import LeftPartComponent from "../homeBody/LeftPartComponent";

function ConsultancyBodyFirst() {
  return (
    <div style={{ marginTop: "10rem" }}>
      <div className="normal-flex-with-row">
        <LeftPartComponent
          firstHeading="HOME"
          secondHeading="CONSULTANCY"
          secondSubText="ISO certification consultancy"
        />
        <div className="image-wrapper added-text">
          <p>
            We offer a full range of{" "}
            <span className="bold-text-with-vertical-padding">
              ISO Certification Consultancy
            </span>{" "}
            services geared towards helping small, medium and large businesses
            and organizations achieve competitiveness, and certifications after
            meeting the requirements of international standards and regulations.
            We fully assist companies and organizations that want to achieve
            compliance or product or system certification as well as want to
            implement FSMS, FSSC, BRCGS, HACCP, quality, environmental,
            integrated management system, HSE, ISMS, CMM and CE mark product
            certifications. We have a strong consulting team which has helped
            leading companies across the globe for explanations on the various
            standards and regulations. Our online and offline consultancy
            services include training, documentation, implementation, follow-up
            etc., for audit, so as to establish and develop the system with
            latest series of standards recognized globally.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ConsultancyBodyFirst;
