import React from "react";
import "../styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function MultipleBannerBodies() {
  const strings = [
    {
      stringStart: `Building a `,
      coloredString: "Brighter and Sustainable Future",
      stringEnd: " of Your Business",
      subString:
        "One Stop Solution for all your ISO Certification Requirements",
    },
    {
      stringStart: `Food and `,
      coloredString: "Feed",
      stringEnd: " Safety",
      subString: "ISO & GFSI Approved Schemes",
    },
    {
      stringStart: `Food `,
      coloredString: "Sustainability",
      subString: "FOS C-O-C, MSC C-O-C",
    },
    {
      stringStart: `Quality, `,
      coloredString: "Environmental",
      stringEnd: ", H&S",
    },
    {
      stringStart: `Sustainability & `,
      coloredString: `CSR..`,
      subString: "FSC C-O-C, ESG, GRI",
    },
    {
      stringStart: `Product  `,
      coloredString: `Certification..`,
      subString: "CE, BIS, ORGANIC, NON GMO, GLUTEN FREE, VEGAN",
    },
    {
      stringStart: `Regulatory `,
      coloredString: ` Compliance..`,
      subString: "FSSAI – Third Party Audits",
    },
    {
      stringStart: `MSME  `,
      coloredString: `Sustainability..`,
      subString: "ZeD – Bronze / Silver / Gold",
    },
    {
      stringStart: `Capacity  `,
      coloredString: ` Building...`,
    },
  ];

  return (
    <div className="body-wrapper">
      <Carousel
        autoPlay
        infiniteLoop
        showIndicators={false}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        transitionTime={0}
        interval={4000}
      >
        {strings.map((item) => {
          return (
            <div className="transition-class-with-fade">
              <p className="body-text-bolder">
                {item?.stringStart}
                <span className="body-text-bolder added-text-color">
                  {item?.coloredString}
                </span>
                {item?.stringEnd}
              </p>
              <p className="body-text-bold">{item.subString}</p>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}

export default MultipleBannerBodies;
