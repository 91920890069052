import React from "react";
import LeftPartComponent from "../homeBody/LeftPartComponent";

function CompanyFourthBody() {
  return (
    <div>
      <div className="normal-flex-with-row">
        <LeftPartComponent
          firstHeading="Company"
          secondHeading="Photo Gallery"
          secondSubText=""
        />
        <div className="image-wrapper added-text">
          <p style={{ textAlign: "center" }}>
            <p style={{ fontSize: "2rem", color: "grey" }}>
              PHOTO{" "}
              <span
                style={{ fontSize: "2rem", fontWeight: "bold", color: "green" }}
              >
                GALLERY
              </span>
            </p>
            <span style={{ fontStyle: "italic" }}>
              Pictures of event & key milestones of our Journey of Success
            </span>
          </p>
          <div>
            <img
              style={{ margin: "0.5rem" }}
              height={200}
              width={350}
              src="/Company1.jpg"
              alt="logo"
            />
            <img
              style={{ margin: "0.5rem" }}
              height={200}
              width={350}
              src="/Company2.png"
              alt="logo"
            />
            <img
              style={{ margin: "0.5rem" }}
              height={200}
              width={350}
              src="/Company3.jpg"
              alt="logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyFourthBody;
