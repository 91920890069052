import React from "react";
import "../styles.css";

function SixthBody() {
  return (
    <div>
      <div className="normal-flex-with-row">
        <div className="second-body-text">
          {" "}
          <div className="text-wrapper">
            <p className="background-color blue">HOME</p>
          </div>
          <div className="text-wrapper">
            <p className="background-color orange">
              E-CONSULTANCY
              <br></br>
              <span className="subtext">online iso certification solution</span>
            </p>
          </div>
        </div>
        <div className="image-wrapper added-text">
          <p>
            Our{" "}
            <span className="bold-text-with-vertical-padding">
              E-Consultancy
            </span>
            , online system certification consulting program is suitable for
            those companies that need minimum disruption of their operation and
            would like to save consultant's travel expenses. Our online service
            is very similar to traditional on-site consulting, except that most
            communication between our experienced consultants and clients is
            done by phone, e-mail Team or Skype etc. We offer mainly three types
            of online consultancy as given below: <br></br>E-CONSULTANCY FOR
            TOTAL CERTIFICATION<br></br> We provide online consulting support
            for ISO management system implementation, documentation, training
            and certification to customers from India and all other countries.
            <br></br>E-DOCUMENTATION CONSULTANCY <br></br>It costs you a
            fraction of the consulting fees charged by us against benefits. We
            collect information online from clients and prepare documentation as
            per their system requirements and deliver them via e-mail and other
            online medium, which saves time and cost of appointing consultant
            for the same work. <br></br>E-TRAINING CONSULTANCY <br></br>We
            provide online training to companies that have internal resources to
            implement their own management system but want to get proper
            training to speed up the system implementation and enhance knowledge
            of team.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SixthBody;
