import React from "react";
import LeftFooter from "./LeftFooter";
import RightFooter from "./RightFooter";
import "../styles.css";

function HomeFooter() {
  return (
    <div className="row-footer-wrapper">
      <LeftFooter />
      <RightFooter />
    </div>
  );
}

export default HomeFooter;
