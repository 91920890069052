import React from "react";
import ServiceFirstBody from "../../pageBody/servicesBody/ServiceFirstBody";
import Footer from "../../footer";

export default function ServicePage() {
  return (
    <div>
      <ServiceFirstBody />
      <Footer />
    </div>
  );
}
